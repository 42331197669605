import React, { useEffect, useContext, useState, useRef } from "react";
import { useFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { isNil } from "lodash";
import * as Sentry from "@sentry/react";
import { compose, mergeSettings } from "../../helpFunctions/general";
import { DataStoreContext } from "./DataStoreContext";
import { TeamBar, ToastMessageContainer, ToastMessage } from "./style";
import { FrButton } from "../DesignSystem/style";
import { ErrorCircle } from "@styled-icons/boxicons-regular/ErrorCircle";
import CustomSelect from "../../components/CustomSelect";
import { OrganizationOption } from "../../components/CustomSelect/customSelectOptions";
import {
  scenariosWithMetadata,
  scenarioIdsWithAccess,
  setOfAllChildScenarios,
  fetchAllChildScenarios,
} from "../../helpFunctions/scenario";
import { checkManager, checkSuperAdmin, getIdsWithRoles, getUserType } from "../../helpFunctions/auth";
import { PrivacyPolicy } from "../UserGuide";
import { settings } from "../../constants/defaults";

const mapToListWithId = obj => {
  return Object.keys(obj || {})
    .filter(key => obj[key] !== null)
    .map(key => ({
      id: key,
      ...obj[key],
    }));
};

const handleError = err => {
  console.error(err);
};

/**
 * The DataStore loads data from Firebase and provides it to the application through the DataStoreContext, typically represented as the props `state` (React Context).
 * The component also contains a few global elements that we might want to render on the page depending on status of the logged in user.
 * It's important to be careful to not load unnecessary data as this can cause high data use. This component will load on every reload of the page.
 *
 * We use selectedUser and selecteOrg to determine what data to load. This is so that an admin can impersonate or choose between multiple user or orgs.
 * For security, the actual permission check also has to happen on Firebase side.
 *
 * We load the following data:
 * - Current organization data if user has organization
 * - All organizations if user is super admin
 * - All scenarios available from selectedOrg and the user's claims
 * - Load all users in org if admin (to display in admin views)
 * - Last 3 personal sessions if regular user, all sessions (but filtered on client) if admin
 *
 */
const DataStore = props => {
  const { dispatch, state } = useContext(DataStoreContext);
  const [emailSent, setEmailSent] = useState(false);
  const sessionListenerRef = useRef(null);

  const firebase = useFirebase();

  const loadScenarios = async (authUser, organization) => {
    let scenarioMap = {};
    if (checkSuperAdmin(authUser?.claims)) {
      scenarioMap = await firebase.fetchAllScenarios();
    } else {
      let scenarioIds = scenarioIdsWithAccess(organization, authUser);
      if (scenarioIds.length > 0) {
        // Tries to download the minimum amount of scenarios based on first and last id
        // this will save a lot of data in most cases, but will also include scenarios
        // "in between" that we may not have access to.
        scenarioMap = await firebase.fetchScenariosBetweenIds(scenarioIds);
      }
    }

    // Fetch all child scenarios that are not already loaded
    const childScenarios = setOfAllChildScenarios(Object.values(scenarioMap || {}));
    await fetchAllChildScenarios(childScenarios, scenarioMap, firebase);

    // TODO we maybe already checked access in scenarioIdsWithAccess, so there could be a more optimized way to do this
    const scenarios = scenariosWithMetadata(scenarioMap, organization?.settings, authUser);
    dispatch({
      type: "SET_SCENARIOS",
      scenarios,
    });
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
    console.log(`Loaded all ${scenarios?.length} scenarios available to user (for org ${organization?.id})`);
  };

  const loadSessionsFromSpecifiedStartingDate = () => {
    return firebase.listenAllSessionsFromDate(state.sessionsFromDate, sessionMap => {
      dispatch({ type: "SET_SESSIONS", sessions: sessionMap });
      console.log("Updated list of sessions with most recent data");
    });
  };

  const loadOrganizations = async (authUser, selectedOrg) => {
    let organizations = [];
    let selectedOrgObject = {};
    const isSuperAdmin = checkSuperAdmin(authUser?.claims);
    // TODO this returns direct claims to scenarios, which we actually don't want
    // but we may have legacy users which relies on a claim like `<orgcom>` instead of `<orgcom>_all`
    // so we don't yet dare to exlcude role-less claims
    let claimedOrgs = getIdsWithRoles(authUser?.claims, null);
    if (!isSuperAdmin && selectedOrg && claimedOrgs.indexOf(selectedOrg) === -1 && claimedOrgs.length > 0) {
      console.error(
        `User does not have access to selected org ${selectedOrg}, changing to first available: ${claimedOrgs[0]}`
      );
      dispatch({ type: "SET_SELECTED_ORG", selectedOrg: claimedOrgs[0] });
      return;
    }
    if (isSuperAdmin) {
      // Super admin loads all orgs
      organizations = mapToListWithId(await firebase.fetchOrganizations());
    } else if (claimedOrgs.length) {
      let orgs = await firebase.getSpecificOrganizations(claimedOrgs);
      organizations = mapToListWithId(orgs);
    } else {
      console.warn("User does not have access to any organizations");
    }
    selectedOrgObject = organizations.find(org => org.id === selectedOrg) || {};

    console.log(`Loaded all ${organizations.length} organizations available to user`);
    dispatch({ type: "SET_ORGANIZATIONS", organizations });
    dispatch({ type: "SET_ORGANIZATION", organization: selectedOrgObject });
  };

  const loadMergedSettings = (authUser, organization) => {
    // Merge in user and org settings to default settings
    const merged = mergeSettings([settings, authUser?.settings, organization?.settings]);
    console.log(`Loaded ${Object.keys(merged || {}).length} merged settings`);
    dispatch({ type: "SET_SETTINGS", settings: merged });
  };

  const loadOrganizationUsers = async organizationId => {
    if (!organizationId) return;
    const userMap = await firebase.getUsersInOrganization(organizationId);
    const users = mapToListWithId(userMap);
    console.log("Loaded all users in org as user is admin");
    dispatch({ type: "SET_USERS", users: users });
  };

  // Note on loading order and exhaustive deps:
  // We use useEffects to create a dependency tree for loading. Whenever a dependency changes, all
  // dependent branches and sub-branches in the tree will be loaded. This is carefully setup to work so
  // don't change without detailed analysis.
  // Note that we set to ignore exhaustive-deps because we don't want to trigger useEffects on all technically
  // dependent changes, just the ones we decide.

  // Set selected user and org from authUser
  useEffect(() => {
    if (props.authUser.userId) {
      dispatch({ type: "SET_SELECTED_USER", selectedUser: props.authUser.userId });
      // setPreviousSelectedUser(state.selectedUser)
    }
    if (props.authUser.organization) {
      dispatch({ type: "SET_SELECTED_ORG", selectedOrg: props.authUser.organization });
      // setPreviousSelectedOrg(state.selectedOrg)
    }
    console.log(
      `Set selected user (${props.authUser?.userId}) and org (${props.authUser?.organization}) from authUser on initial load`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authUser]);

  // Load all organizations available to user (based on claims)
  useEffect(() => {
    if (state.selectedUser) {
      loadOrganizations(props.authUser, state.selectedOrg).catch(handleError); // TODO, should be based on selected user
    }
    // Tell Sentry what the current selected org and user type user is active
    Sentry.setTag("selectedOrg", state.selectedOrg);
    Sentry.setTag("userRole", roleName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedUser, state.selectedOrg]);

  // Load all scenarios and organization users available (based on org, and claims)
  useEffect(() => {
    // Load scenarios only if state.organization has been resolved (not null, but can be empty obj)
    if (props.authUser && !isNil(state.organization)) {
      loadScenarios(props.authUser, state.organization).catch(handleError);
      loadMergedSettings(props.authUser, state.organization);
    }

    if (checkManager(props.authUser?.claims, state.selectedOrg)) {
      loadOrganizationUsers(state.selectedOrg).catch(handleError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.organization, props.authUser]);

  // Load sessions whenever on selected from date changes
  useEffect(() => {
    if (state.sessionsFromDate !== null) {
      sessionListenerRef.current = loadSessionsFromSpecifiedStartingDate();
    }
    return () => {
      if (sessionListenerRef.current) {
        sessionListenerRef.current();
        sessionListenerRef.current = null;
        console.log("Stopped listening to recent sessions");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sessionsFromDate]);

  const sendVerificationEmail = () => {
    if (!emailSent) {
      firebase.doSendEmailVerification();
      setEmailSent(true);
    }
  };

  const onOrgChange = e => {
    dispatch({
      type: "SET_SELECTED_ORG",
      selectedOrg: e.id,
    });
  };

  /**
   * Changes the current organization for a user, used by admins.
   */
  const changeOrganization = () => {
    // TODO check that we can select this org
    firebase.updateUser(props.authUser.userId, {
      organization: state.selectedOrg,
    });
  };

  const selectedTeamName =
    state.organizations?.find(org => org.id === state.selectedOrg)?.displayName || state.selectedOrg;
  const roleName = getUserType(props.authUser.claims, state.selectedOrg);
  const unverifiedWarning = firebase?.auth?.currentUser?.email && !firebase?.auth?.currentUser?.emailVerified;
  const noOrgWarning = props.authUser && (!props.authUser.organization || props.authUser.organization === "");

  return (
    <>
      {state.selectedOrg && state.organizations?.length > 1 && (
        <TeamBar>
          <label>Current organization</label>
          <div style={{ maxWidth: "300px", minWidth: "150px" }}>
            <CustomSelect
              isSearchable={true}
              isDisabled={false}
              isMulti={false}
              getOptionLabel={option => `${option.id} ${option.displayName}`}
              onChange={e => onOrgChange(e)}
              options={state.organizations}
              value={state.selectedOrg}
              placeholder={`${selectedTeamName} (${roleName})`}
              option={OrganizationOption}
            />
          </div>

          {props.authUser.organization && (
            <FrButton
              onClick={() => changeOrganization()}
              margin={"0 0 0 10px"}
              disabled={props.authUser.organization === state.selectedOrg}
            >
              Save choice
            </FrButton>
          )}
        </TeamBar>
      )}

      {(unverifiedWarning || noOrgWarning) && (
        <ToastMessageContainer>
          {unverifiedWarning && (
            <ToastMessage>
              {!emailSent && (
                <div>
                  <ErrorCircle />
                  <h5>
                    Your email is not verified so your account is limited, check your mailbox for verification email.
                  </h5>
                </div>
              )}
              <div>
                <h5>I have verified my email adress</h5>
                <button onClick={() => window.location.reload()}>Done</button>
                <h5>Can't find it?</h5>
                <button disabled={emailSent} onClick={() => sendVerificationEmail()}>
                  {emailSent ? "Email sent" : "Resend email"}
                </button>
              </div>
            </ToastMessage>
          )}
          {/* TODO this may need to look at claims instead of current organization */}

          {noOrgWarning && (
            <ToastMessage>
              <div>
                <ErrorCircle />
                <h5>
                  You don't belong to an organization and has limited access to this app. Contact your admin or support
                  if this is unexpected.
                </h5>
              </div>
            </ToastMessage>
          )}
        </ToastMessageContainer>
      )}

      {/* {!state.loading && props.authUser && props.authUser.noDbUser && (
        <AddUserToDB doRegisterUser={firebase.doRegisterUser} authUser={props.authUser} />
      )} */}

      {!state.loading && props.authUser && !props.authUser.termsConsent && (
        <PrivacyPolicy authUser={props.authUser} firebase={firebase} history={props.history} dispatch={dispatch} />
      )}
    </>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition, true))(DataStore);
