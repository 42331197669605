import styled from "styled-components";

export const HelpText = styled.div`
  p {
    margin-top: 1em;
  }
`;

export const ScrollableContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid var(--grey-4);
  border-radius: 5px;
  padding: 10px;
`;

export const SettingsContainer = styled.div`
  margin-top: 10px;
  p {
    font-size: 12px;
  }
  max-width: 500px;
`;

export const SettingsList = styled.ul`
  display: flex;

  flex-direction: column;

  li {
    min-height: ${props => (props.compact ? "25px" : "45px")};
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0;
    width: 100%;

    label {
      font-size: ${props => (props.compact ? "12px" : "14px")};
      vertical-align: middle;
      display: flex;
      align-items: center;
      white-space: wrap;
      svg {
        flex: 0 0 auto;
      }
    }
  }
`;

export const IconButton = styled.button`
  flex: 0 0 auto;
  margin-left: 10px;
  white-space: nowrap;
  width: 20px;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  color: #4b4b4b;
  transition: 0.4s;
  opacity: 1;
  background: transparent;
  span {
    font-size: 12px;
  }

  svg {
    margin: 0 2px 0 0;
    color: var(--grey);
  }

  &:hover {
    transition: 0.4s;
    opacity: 0.8;
  }
`;

export const Toggle = styled.input`
  :where([type="checkbox"][role="switch"]) {
    flex: 0 0 auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    font-size: inherit;
    width: 48px;
    height: 28px;
    box-sizing: content-box;
    border: 1px solid;
    border-radius: 20px;
    vertical-align: text-bottom;
    margin: auto;
    color: var(--grey-1);
    background: var(--grey-2);
  }

  // The round toggle
  :where([type="checkbox"][role="switch"])::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(0, -50%);
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    // border: 1px solid;
    border-radius: 50%;
    background: var(--grey-5);
  }

  :where([type="checkbox"][role="switch"]):checked {
    color: none;
    background: var(--key-color-2);
  }

  :where([type="checkbox"][role="switch"]):checked::before {
    left: 22px;
    background: white;
  }

  :where([type="checkbox"][role="switch"]):disabled {
    background: var(--grey-4);
    color: var(--grey-3);
  }
`;
