export const publicStoragePathPattern = /^(thumbs|scenarios|audio|organizations)/;

export function getFileUrl(path) {
  return `https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_STORAGE_BUCKET}/o/${encodeURIComponent(
    path
  )}?alt=media`;
}

export const getPublicUrl = filePath =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      import.meta.env.VITE_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponent(filePath) +
      "?alt=media"
    : null;

export const getThumbUrl = (filePath, size) =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      import.meta.env.VITE_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponent(filePath + `.resized.${size}px.webp`) +
      "?alt=media"
    : null;

export const getSignedOrPublicUrl = eventObj => eventObj.url || getPublicUrl(eventObj.audio) || null;

export const getStorageUrl = filePath =>
  "https://storage.cloud.google.com/" + import.meta.env.VITE_STORAGE_BUCKET + filePath;

export const getAdminConsoleUrl = filePath =>
  "https://console.cloud.google.com/storage/browser/" + import.meta.env.VITE_STORAGE_BUCKET + filePath;

const rtdbId = import.meta.env.VITE_REALTIME_DB.split(".")[0].replace("https://", "");

export const getRTDBConsoleUrl = path =>
  "https://console.firebase.google.com/u/0/project/" +
  import.meta.env.VITE_PROJECT_ID +
  "/database/" +
  rtdbId +
  "/data/" +
  encodeURIComponent(path);

// Example input string: 2024-09-13T09:57:13.906065Z
// Example output string: 2024-09-13T09:59:13.906065Z
const dateWithOffset = (isoDateString, offsetMinutes) => {
  // Parse the ISO date string to a Date object
  const date = new Date(isoDateString);

  // Apply the offset in minutes
  date.setMinutes(date.getMinutes() + offsetMinutes);

  // Convert the updated Date object back to an ISO date string
  return date.toISOString();
};

export const getGoogleLogUrl = (sessionId, created) =>
  `https://console.cloud.google.com/logs/query;query=labels.session_id%3D%22${sessionId}%22;startTime=${dateWithOffset(created, -10)};endTime=${dateWithOffset(created, 90)}?project=${import.meta.env.VITE_PROJECT_ID}`;
