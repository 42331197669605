import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { getBackendUrl } from "./helpFunctions/general";

const history = createBrowserHistory();

if (import.meta.env.VITE_LOCAL_BACKEND) {
  console.log("Running in local backend mode");
}
// To test Sentry on local dev server, manually run `VITE_SENTRY_DEV=true npm run start-dev`
if (import.meta.env.NODE_ENV === "production" || import.meta.env.VITE_SENTRY_DEV === "true") {
  const sampleRate = parseFloat(import.meta.env.VITE_SENTRY_TRACING_SAMPLE_RATE || "1.0");
  const tracePropagationTargets = ["localhost", /^\//, getBackendUrl("")];
  // Set if running from output of npm run build
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    release: "fictive-front@" + import.meta.env.VITE_VERSION,
    environment: import.meta.env.VITE_SENTRY_DEV ? "local-dev" : import.meta.env.VITE_PROJECT_ID,
    tracesSampleRate: sampleRate,
    sendDefaultPii: false,
    tracePropagationTargets,
  });
  console.log(`Initiated Sentry error tracing with sample rate ${sampleRate}`, tracePropagationTargets);
}
console.log(`Entered app with URL ${window.location.href}`);
console.log("Version " + (import.meta.env.VITE_VERSION || import.meta.env.NODE_ENV));
console.log("Environment", import.meta.env.VITE_PROJECT_ID);
console.log("Database", import.meta.env.VITE_REALTIME_DB);

ReactDOM.render(<App />, document.getElementById("root"));
