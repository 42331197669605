import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DataStoreContext } from "../../components/DataStore/DataStoreContext";
import { FrButton, ToolbarContainer, ToolbarSeparator } from "../../components/DesignSystem/style";
import { useFirebase } from "../../components/Firebase";
import Footer from "../../components/Footer";
import { createFormStore, DebugScenarioForm, FormContext, useForm } from "../../components/FormProvider";
import { withAuthorization } from "../../components/Session";
import SettingsEditor from "../../components/SettingsEditor";
import { settings as defaultSettings } from "../../constants/defaults";
import settings from "../../constants/settings.json";
import { checkManager, getUserType } from "../../helpFunctions/auth";
import { applyUpdateObject, compose, lodashToFirebaseUpdateObject } from "../../helpFunctions/general";
import { AdminNavigation, DetailedPageTitle } from "../Admin/AdminHeader";
import { AdminContainer } from "../Admin/style";

// TODO currently no defaults or validation
const defaultOrganizationValue = {};
const orgValidationSchema = {
  properties: {
    settings,
  },
};

export const OrganizationFormProvider = ({ selectedOrg, children }) => {
  // We are using vanilla (non-React) entrypoint to create the zustand store, then keep it as a reference
  // That means the store needs to be wrapped with useStore in a component to actually be reactive
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createFormStore(defaultOrganizationValue, orgValidationSchema);
  }

  return (
    <FormContext.Provider value={{ store: storeRef.current }}>
      <DebugScenarioForm />
      {children}
    </FormContext.Provider>
  );
};

const Organization = ({ state, dispatch, authUser }) => {
  const firebase = useFirebase();
  const [isSaving, setIsSaving] = useState(false);
  const isDirty = useForm(state => state.isDirty);
  const { store } = useContext(FormContext);

  useEffect(() => {
    store.getState().setSavedData(state.organization);
  }, [state.organization, store]);

  const handleSave = async () => {
    setIsSaving(true);
    const storeState = store.getState();
    const { isValid } = storeState.validate(true);
    if (!isValid) {
      toast("Some fields have errors. Please check all red fields before proceeding.", { type: "error" });
      setIsSaving(false);
      return;
    }

    let { id, ...updateObject } = store.getState().changes || {};
    if (isEmpty(updateObject)) {
      console.log("No changes to save");
    } else {
      updateObject = lodashToFirebaseUpdateObject(updateObject);
      console.log(`Saving data to org ${state.selectedOrg}`, updateObject);
      try {
        await firebase.updateOrganization(state.selectedOrg, updateObject);
        dispatch({ type: "SET_ORGANIZATION", organization: applyUpdateObject(state.organization, updateObject) });
      } catch (error) {
        if (error?.message) {
          toast(error.message, { type: "error" });
        } else {
          toast("Failed to save organization. Please try again or contact support.", { type: "error" });
        }
      }
    }
    setIsSaving(false);
  };

  return (
    state.selectedOrg && (
      <AdminContainer>
        <ToastContainer />
        <DetailedPageTitle
          title={"Admin"}
          organization={state.organization || state.selectedOrg}
          userType={getUserType(authUser?.claims, state.selectedOrg)}
        />
        <AdminNavigation />
        <SettingsEditor
          // Org settings inherit from global defaults. Doesn't make much sense to inherit from user here.
          defaultSettings={defaultSettings}
          settingKeys={[
            "sendActivityEmail",
            "debugMode",
            "alwaysShowHelp",
            "dialogueAppChannel",
            "dataRetentionDays",
            "appLogo",
            "appName",
            "lipsyncStrength",
            "freeMovement",
            "openMic",
            "graphicsQuality",
            "backgroundVolume",
            "speechVolume",
            "uiVolume",
            "hideIngameUI",
            "showSubtitles",
            "chatTimeout",
            "scenarioDocumentsFeature",
            "webAvatarFeature",
            "scenarioWizardFeature",
            "multilingualScenarioFeature",
            "newPlayFeature",
            "availableLocales",
            "availableVoiceProviders",
            "availableEnvironments",
            "availableRatings",
            "availableAgentProviders",
            "startInMode",
            "ephemeralSessions",
            "sessionTimeoutSeconds",
            "conversationIdleTimeSeconds",
            "agentTemperature",
            "agentMaxResponseTokens",
            "agentRolePrefixMode",
            "agentUserCanInterrupt",
            "agentSendFillerAudio",
            "agentTrackSentiment",
            "transcriberSegmentationSilenceTimeoutMs",
            "transcriberInitialSilenceTimeoutMs",
          ]}
          fieldPath={"settings"}
          schema={orgValidationSchema.properties.settings.properties}
        >
          <p>
            To change a preference, click to edit it and select its value. It will override default preferences or user
            preferences (but may be overridden by scenario specific preferences). Remove the preference if you no longer
            want to override.
          </p>
          <p>
            <strong>Warning</strong> these are advanced settings that may create unexpected behavior if not used
            correctly.
          </p>
        </SettingsEditor>
        <Footer />
        <ToolbarContainer>
          <ToolbarSeparator />
          <FrButton padding={"9px 20px"} fontSize={"18px"} disabled={!isDirty || isSaving} onClick={handleSave}>
            {isSaving ? "Saving..." : isDirty ? "Save" : "Saved"}
          </FrButton>
        </ToolbarContainer>
      </AdminContainer>
    )
  );
};

const OrganizationWrapper = props => {
  const { state, dispatch } = useContext(DataStoreContext);
  return (
    <OrganizationFormProvider id={state.selectedOrg} dispatch={dispatch}>
      <Organization state={state} dispatch={dispatch} {...props} />
    </OrganizationFormProvider>
  );
};

const condition = (authUser, state) => checkManager(authUser?.claims, state.selectedOrg);

export default compose(withAuthorization(condition))(OrganizationWrapper);
